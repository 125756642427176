/* @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    src: local('ProximaNova'), url('./fonts/ProximaNova.otf') format('opentype');
    font-display: fallback
  } */

  @font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-thin-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-light-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-regular-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-medium-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-semibold-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-bold-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-extrabold-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-black-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    text-rendering:optimizeLegibility;
}

/* italic */
@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-thinit-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-thinit-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-lightit-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-lightit-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-regularit-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-regularit-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-mediumit-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-mediumit-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-semiboldit-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-semiboldit-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-boldit-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-boldit-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-extraboldit-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-extraboldit-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/proximanova-blackit-webfont.woff2') format('woff2'),
         url('./fonts/proximanova-blackit-webfont.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    text-rendering:optimizeLegibility;
}

@font-face {
    font-family: 'Halter';
    src: url('./fonts/Halter.woff') format('woff');
    font-weight: 400;
    text-rendering:optimizeLegibility;
}

