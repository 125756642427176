/* type submenu */

.submenu .ant-popover-arrow{
    display:none;
}

/* type button */
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow{
    right: 28px;
}

.button .ant-popover-inner{
    border-radius: 5px ;
    
}

/* type menu  */

.menu .ant-popover-arrow{
    display:none;
}

/* style for all type */
.ant-popover-arrow{
    border-color: #2D3546 !important;
    width: 16px;
    height: 16px;
    z-index: -1;;
}

.ant-popover-inner-content{
    padding:0 !important;
}

.ant-popover-placement-leftTop{
    padding:11px 0 0 12px;
}

.ant-popover-placement-rightTop{
    padding:0;
    left: 131px !important;
}


.ant-popover{
    color:#2D3546;
}

.ant-popover-arrow{
    background:#2D3546;
}